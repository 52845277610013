import { useLocation } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import { parse as queryParse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { scroller } from "react-scroll"
import CustomAccordion from "../components/common/accordian"
// import Advantage from "../components/common/Advantage-box/Advantage"
import Benefit from "../components/common/Benefit-box/Benefit"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import PopUpForm from "../components/common/PopUpForm"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/refer-a-friend.scss"

function ReferAFriend() {
  const location = useLocation()
  const searchParams = queryParse(location.search)
  const getScrollId = location.hash

  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  const formdata = [
    {
      name: "referrer_name",
      type: "text",
      label: "Your name",
      errorMessage: "Please enter referrer name",
    },
    {
      name: "referrer_email",
      type: "email",
      label: "Your email",
      errorMessage: "Please enter your email ID",
    },
    {
      name: "firstname",
      type: "text",
      label: "Name of the person you want to refer",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Referral's email",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Referral's company name",
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Referral's mobile number",
      errorMessage: "Please enter a mobile number",
    },
  ]

  const ctaLinkProps = {
    className: "Layout-container",
    maxDescriptionWidth: "500px",
    lgLeft: 7,
    lgRight: 4,
    rightStyles: { alignItems: "center" },
  }
  const ctaCardProps = {
    bgPattern: "true",
    className: "Layout-max",
    maxDescriptionWidth: "475px",
    maxBoxWidth: "1400px",
    maxRowWidth: "1176px",
    lgLeft: 5,
    lgRight: 7,
  }

  const makeSlug = str => {
    var string = ""
    if (!!str) {
      string = str.replace(/\s+/g, "-").toLowerCase()
    }

    return string
  }

  useEffect(() => {
    if (!!searchParams.block) {
      setTimeout(function () {
        document.getElementById(`${searchParams.block}-link`).click()
      }, 2000)
    }
    if (getScrollId !== null) {
      scroller.scrollTo(getScrollId)
    }
  })

  return (
    <>
      <StaticQuery
        query={graphql`
          query referAFriend {
            SuperOps {
              pages(where: { title: "refer a friend" }) {
                title
                slug
                subtitle
                linkTitle
                pageBlock {
                  content {
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                }
                faqComponent {
                  heading
                  description
                  faqs {
                    question
                    answerrt {
                      html
                    }
                  }
                }
                newField {
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    heading
                    title
                    subtext
                    image {
                      url
                    }
                    content {
                      html
                    }
                  }
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const {
                navigationBlock,
                seo,
                title,
                subtitle,
                pageBlock,
                linkTitle,
                faqComponent,
                newField,
              } = page

              return (
                <div className="refer-a-friend">
                  <Frame seo={seo}>
                    <header>
                      <Navigation links={navigationBlock} page="About us" newButton />
                    </header>

                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_REFER_ENDPOINT}
                        url={process.env.HUBSPOT_REFER_URL}
                        formName="Refer a friend form- Superops"
                        successMsg="Thank you! We will get in touch with them on your behalf! Want
                        to refer more people?"
                        successBtnText="Refer again"
                        additionalFormProps={{ referFriend: true, IpStackData: true }}
                      />
                    </div>

                    <section className="section-wrapperr">
                      <Hero
                        background={"linear-gradient(180deg, #FFC2C8 0%, #F6F0E6 100%)"}
                        tag={title}
                        heading={pageBlock[0].content.text}
                        maxHeadingWidth="750px"
                        maxDescriptionWidth="374px"
                        description={subtitle}
                        primaryButtonText={"REFER A FRIEND"}
                        primaryButtonLink={"/blog"}
                        maxImageWidth="500px"
                        marginBottom="-180px"
                        lgLeft={12}
                        lgRight={0}
                        referBox={newField[1]}
                        openPopUp={() => togglePopup()}
                        referFriend
                        hideImage
                        newButton
                      />
                      <Benefit
                        type="SuperOps_Benefits"
                        title={newField[0].heading}
                        heading={newField[0].title}
                        icon={newField[0].image}
                        arrowSVG="benefitsSideArrow-1"
                        descriptionHtml={newField[0].content}
                      />

                      <div className="faq">
                        <Container className="Layout-container">
                          <Row className="justify-content-between">
                            <Col lg={4}>
                              <h2 className="refer-faq-head font-roboto">{faqComponent.heading}</h2>
                              <p>{faqComponent.description}</p>
                            </Col>
                            <Col lg={8}>
                              <div className="section">
                                <CustomAccordion items={faqComponent.faqs} />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </section>
                    <CTAThankYou />
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default ReferAFriend
