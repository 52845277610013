import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Tilt from "react-parallax-tilt"
import Fade from "react-reveal/Fade"
// import "../../../styles/component/cta.scss"
import parse from 'html-react-parser'
import Slide from "../../utility/slide_content"
import SVGIcon from "../SVGIcon"
import "./Benefit.scss"

const Benefit = props => {
  const { title, icon, heading, description, iconBgColor, arrowSVG, descriptionHtml } = props
  let iconLen = icon.length //array of object
  const [curInd, setCurInd] = useState(0)
  const [animTrack, setAnimeTrack] = useState("")

  const changeCard = val => {
    if (val === "plus") {
      if (curInd < iconLen - 1) {
        setTimeout(() => {
          setCurInd(curInd + 1)
          setAnimeTrack(val)
        }, 500)
        setAnimeTrack("L_T_R")
      }
    } else {
      if (curInd > 0) {
        setTimeout(() => {
          setCurInd(curInd - 1)
          setAnimeTrack(val)
        }, 500)
        setAnimeTrack("R_T_L")
      }
    }
  }

  return (
    <div className="Benefit-box">
      <Container className="Layout-container">
        {
          <>
            <Slide delay="200">
              <div className="mob position-relative">
                {title && (
                  <div className="position-relative title-wrapper">
                    <h4 className="title mx-auto">{title}</h4>
                  </div>
                )}
                <div className="benefit-wrapper position-relative">
                  {title && (
                    <div className="position-relative">
                      <SVGIcon
                        name="feat-page-downArrow"
                        className="position-absolute"
                      ></SVGIcon>
                    </div>
                  )
                  }

                  {icon.map((icn, index) => {
                    return (
                      <>
                        <div
                          className={`${curInd === index && animTrack === "minus"
                            ? "in-L-T-R"
                            : ""
                            } ${curInd === index && animTrack === "plus"
                              ? "in-R-T-L"
                              : ""
                            } ${animTrack === "L_T_R" ? "out-R-T-L" : ""}
                            ${animTrack === "R_T_L" ? "out-L-T-R" : ""
                            } tilt-wrapper position-absolute1`}
                          style={{
                            display: `${index === curInd ? "block" : "none"}`,
                            // opacity: `${index === curInd ? "1" : "0"}`,
                            // transition: "all 0s ease",
                          }}
                        >
                          <Tilt
                            className="parallax-effect-glare-scale"
                            perspective={800}
                            scale={1.02}
                          >
                            <div className={`column-box-mob`}>
                              <div className="benefit-box-anime">
                                <div
                                  className="icon-wrapper d-flex align-items-center justify-content-center"
                                // style={{
                                //   background: iconBgColor[index]?.hex,
                                // }}
                                >
                                  <img
                                    src={icn.url}
                                    alt="img"
                                    className="icon w-100"
                                  />
                                </div>
                                <h5 className="heading">{heading[index]}</h5>

                                {description?.[index] && (
                                  <div className="p16 p content-wrapper">
                                    <p className="content">{description[index]}</p>
                                  </div>
                                )}
                                {descriptionHtml?.[index] && (
                                  <div className="p16 p content-wrapper">
                                    {parse(descriptionHtml[index]?.html)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Tilt>
                        </div>
                      </>
                    )
                  })}
                </div>
                <div className="sliderBtnWrap d-flex justify-content-center align-items-center">
                  <Fade bottom delay={100} distance={"60px"} duration={1000}>
                    <button
                      className={`carousel-btn ${curInd === 0 ? "disabled" : ""
                        }`}
                      onClick={() => changeCard("minus")}
                    >
                      <SVGIcon name="carousel-left" />
                    </button>
                    <span className="slide-pagination">
                      {curInd + 1}/{iconLen}
                    </span>
                    <button
                      className={`carousel-btn ${curInd === iconLen - 1 ? "disabled" : ""
                        }`}
                      onClick={() => changeCard("plus")}
                    >
                      <SVGIcon name="carousel-right" />
                    </button>
                  </Fade>
                </div>
              </div>
            </Slide>

            <div className="d-dsk">
              <Row className="box-wrapper">
                {title && (
                  <Col lg={3}>
                    <Slide delay={100}>
                      <div className="position-relative title-wrapper">
                        <h4 className="title">{title}</h4>
                        <SVGIcon
                          name={arrowSVG}
                          className="curv-arrow position-absolute"
                        ></SVGIcon>
                      </div>
                    </Slide>
                  </Col>
                )}

                {icon.map((icn, index) => {
                  return (
                    <Col lg={title ? 3 : 4}>
                      <Slide delay={(index + 1) * 200} className="h-100">
                        <Tilt
                          className="parallax-effect-glare-scale"
                          perspective={800}
                          scale={1.02}
                        >
                          <div className="col-box-wrapper">
                            <div className="column-box">
                              <div
                                className="icon-wrapper d-flex align-items-center justify-content-center"
                              // style={{
                              //   background: iconBgColor[index]?.hex,
                              // }}
                              >
                                <img
                                  src={icn.url}
                                  alt="img"
                                  className="icon w-100"
                                />
                              </div>
                              <h5 className="heading fw-bold">
                                {heading[index]}
                              </h5>

                              {description?.[index] && (
                                <div className="p16 p content-wrapper">
                                  <p className="content">{description[index]}</p>
                                </div>
                              )}
                              {descriptionHtml?.[index] && (
                                <div className="p16 p content-wrapper">
                                  {parse(descriptionHtml[index]?.html)}
                                </div>
                              )}
                            </div>
                          </div>
                        </Tilt>
                      </Slide>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </>
        }
      </Container>
    </div>
  )
}

export default Benefit
